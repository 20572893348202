import React from "react"
import flutterLogo from "../../images/Flutter.svg"
import { Link } from "gatsby"
import StoreLinks from "../store-links/StoreLinks"

const LandingPageContent = () => {
  return (
    <div className="home-content">
      <div className="content-header">
        <h1 className="title">Dashtronaut</h1>
        <h2 className="subtitle">
          Solve A Slide Puzzle With Dashtronaut,
          <span style={{ whiteSpace: "nowrap" }}> Dash the Astronaut 🚀</span>
        </h2>
        <p>
          This slide puzzle game was created with
          <span style={{ whiteSpace: "nowrap" }}>
            &nbsp;
            <a target="_blank" rel="noreferrer" href="https://flutter.dev/">
              Flutter
            </a>{" "}
            💙
          </span>
          &nbsp; for the &nbsp;
          <a
            style={{ whiteSpace: "nowrap" }}
            target="_blank"
            rel="noreferrer"
            href="https://devpost.com/software/dashtronaut?ref_content=my-projects-tab&ref_feature=my_projects"
          >
            Flutter Puzzle Hack Challenge
          </a>
        </p>
        <p>
          <small>
            Built By &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/roaakdm"
            >
              @roaakdm
            </a>
          </small>
        </p>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-md-1" />
        <div className="col-12 col-md-5">
          <div className="download-links links-list">
            <h4>Download Now</h4>
            <StoreLinks />
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="links links-list">
            <h4>Links</h4>
            <a
              href="https://play.dashtronaut.app"
              target="_blank"
              rel="noreferrer"
              className="web-app-badge links-badge mt-4"
            >
              <i className="fa-solid fa-arrow-up-right-from-square" />
              <span className="d-inline-block">Launch Web App</span>
            </a>
            <Link
              to="/tutorials/introduction"
              className="for-flutter-developers-link links-badge"
            >
              <img src={flutterLogo} alt="" />
              <span>For Flutter Developers 💙</span>
            </Link>
            <a
              href="https://github.com/Roaa94/flutter-puzzle-hack"
              target="_blank"
              rel="noreferrer"
              className="web-app-badge links-badge text-center"
            >
              <i className="fab fa-github"/>
              <span className="d-inline-block">The Source Code</span>
            </a>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  )
}

export default LandingPageContent
