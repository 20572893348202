import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../stylesheets/landing-page.css"
import LandingPageContainer from "../components/landing-page/LandingPageContainer"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <LandingPageContainer />
  </Layout>
)

export default IndexPage
