import React from "react"
import topLeftPlanet from "../../images/background/top-left-planet.png"
import bottomRightDash from "../../images/background/bottom-right-dash.svg"
import bottomLeftPuzzle from "../../images/background/bottom-left-puzzle.svg"
import topRightPlanets from "../../images/background/top-right-planets.png"
import LandingPageContent from "./LandingPageContent"
import { Link } from "gatsby"

const LandingPageContainer = () => {
  return (
    <div className="landing-page-container">
      <div className="h-100">
        <img
          className="bg-item top-left-item"
          src={topLeftPlanet}
          alt=""
          width="200px"
        />
        <img
          className="bg-item bottom-right-item"
          src={bottomRightDash}
          alt=""
          width="200px"
        />
        <img
          className="bg-item bottom-left-item"
          src={bottomLeftPuzzle}
          alt=""
          width="200px"
        />
        <img
          className="bg-item top-right-planet"
          src={topRightPlanets}
          alt=""
          width="200px"
        />
      </div>

      <LandingPageContent />
      <div className="footer">
        <Link to="privacy-policy">Privacy Policy</Link>
      </div>
    </div>
  )
}

export default LandingPageContainer
